@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap);
:root {
  --very-dark-text: #2b2b2b;
  --dark-text: #969696;
  --white-text: #fff;
  --black-text: #000;
  --error-text: #e67a7a;
  --font-primary: 'Rubik', sans-serif;
  --primary-background: url(/static/media/pattern-bg.ce8161cf.png);
  --transition: all 0.2s ease;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 18px;
  font-family: 'Rubik', sans-serif;
}

.py-1 {
  padding: 1rem 0;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.header-text {
  font-size: 28px;
  font-weight: 500;
  color: #fff;
  color: var(--white-text);
  text-transform: capitalize;
  position: static;
}

.overflow-hide {
  overflow: hidden;
}

/* Loader */
.loader,
.loader:after {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.2em solid rgba(255, 255, 255, 0.2);
  border-right: 0.2em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.2em solid rgba(255, 255, 255, 0.2);
  border-left: 0.2em solid #ffffff;
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.map-main-container {
  width: 100%;
  height: 100%;
}

.header-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 1.5rem;
}

.logout-button {
  position: absolute;
  top: 1rem;
  left: 1rem;
  padding: 0.5rem 0.8rem;
  font-size: 1rem;
  background-color: var(--very-dark-text);
  color: var(--white-text);
  border-radius: 10px;
  border: none;
  text-transform: capitalize;
  letter-spacing: 0.07em;
  font-weight: 500;
  cursor: pointer;
  transition: var(--transition);
}
.logout-button:hover {
  background-color: var(--dark-text);
}

.header-background {
  width: 100vw;
  height: 35vh;
  background-image: var(--primary-background);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.header-container .input-button-container {
  margin-top: 1.5rem;
  width: 36%;
}

.header-container .input-button-container input {
  font-size: 18px;
  font-family: var(--font-primary);
  color: var(--very-dark-text);
  padding: 0.9rem 1.2rem;
  border: none;
  width: 100%;
  border-radius: 15px 0 0 15px;
}

.header-container .input-button-container input:focus {
  outline: none;
}

.header-container .input-button-container .icon-button {
  min-width: 50px;
  min-height: 50px;
  background-color: var(--black-text);
  border-radius: 0 15px 15px 0;
  display: grid;
  place-items: center;
  cursor: pointer;
  border: none;
  transition: var(--transition);
}

.header-container .input-button-container .icon-button:hover {
  background-color: var(--very-dark-text);
}

.input-hidden {
  visibility: hidden;
}

.error-msg {
  color: var(--error-text);
  font-size: 14px;
  letter-spacing: 0.05rem;
  margin-top: 0.3rem;
}

@media only screen and (device-width: 812px) and (device-height: 375px) and (-webkit-device-pixel-ratio: 3) {
  .header-background {
    height: 58vh;
  }
  .header-container .input-button-container {
    width: 90%;
  }
}

@media screen and (max-width: 750px) and (max-height: 800px) {
  .header-background {
    height: 55vh;
  }
  .header-container .input-button-container {
    width: 90%;
  }
}

@media (max-width: 500px) {
  .header-background {
    height: 38vh;
  }
  .header-container .input-button-container {
    width: 90%;
  }
}

@media (max-width: 374px) {
  .header-background {
    height: 45vh;
  }
  .header-container .input-button-container {
    width: 90%;
  }
  .logout-button {
    padding: 0.4rem 0.5rem;
  }
}

.responseContainer {
  min-width: 80%;
  min-height: 22%;
  display: flex;
  justify-content: center;
  background-color: var(--white-text);
  border-radius: 15px;
  position: absolute;
  top: 24%;
  padding: 1.5rem 0;
  -webkit-animation: transform-box 0.5s ease-out forwards;
          animation: transform-box 0.5s ease-out forwards;
  z-index: 10;
}

.responseContainer hr {
  border: 1px solid var(--dark-text);
  opacity: 0.2;
  margin: 10px 0;
}
.responseContainer hr:last-child {
  display: none;
}

.responseContainer .service-box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: auto;
  flex: 1 1;
}

.responseContainer .service-box:nth-child(odd) {
  padding: 0 1rem;
}

.responseContainer .service-box .service-title {
  color: var(--dark-text);
  text-align: left;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  font-weight: 500;
  flex: 1 1;
}

.responseContainer .service-box .service-value {
  color: var(--very-dark-text);
  text-align: left;
  text-transform: capitalize;
  letter-spacing: 0.07em;
  font-weight: 500;
  font-size: 22px;
  flex: 2.5 1;
}

@-webkit-keyframes transform-box {
  0% {
    transform: translateY(20%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes transform-box {
  0% {
    transform: translateY(20%);
  }
  100% {
    transform: translateY(0%);
  }
}

@media (max-width: 768px) {
  .responseContainer {
    max-width: 60%;
    min-height: 30%;
    flex-direction: column;
    top: 20%;
  }
  .responseContainer hr {
    opacity: 0;
  }
}

@media only screen and (device-width: 812px) and (device-height: 375px) and (-webkit-device-pixel-ratio: 3) {
  .responseContainer {
    min-width: 90%;
    min-height: 35%;
    flex-direction: row;
    top: 42%;
  }
  .responseContainer hr {
    opacity: 0;
  }
  .responseContainer .service-box {
    align-items: center;
    margin-right: 0 !important;
    color: rebeccapurple;
  }
  .responseContainer .service-box .service-value {
    text-align: center;
    font-size: 15px;
  }
}

@media screen and (max-width: 750px) and (max-height: 800px) {
  .responseContainer {
    min-width: 90%;
    min-height: 35%;
    flex-direction: row;
    top: 38%;
  }
  .responseContainer hr {
    opacity: 0;
  }
  .responseContainer .service-box {
    align-items: center;
    margin-right: 0 !important;
  }
  .responseContainer .service-box .service-value {
    text-align: center;
    font-size: 15px;
  }
}

@media (max-width: 500px) {
  .responseContainer {
    min-width: 90%;
    min-height: 35%;
    flex-direction: column;
    top: 22%;
  }
  .responseContainer hr {
    opacity: 0;
  }
  .responseContainer .service-box {
    align-items: center;
    margin-right: 0 !important;
  }
  .responseContainer .service-box .service-value {
    text-align: center;
    font-size: 18px;
  }
}

@media (max-width: 374px) {
  .responseContainer {
    min-width: 90%;
    min-height: 35%;
    flex-direction: column;
    top: 25%;
  }
  .responseContainer hr {
    opacity: 0;
  }
  .responseContainer .service-box {
    align-items: center;
    margin-right: 0 !important;
  }
  .responseContainer .service-box .service-value {
    text-align: center;
    font-size: 18px;
  }
}

.map-container {
  width: 100%;
}

.leaflet-icon {
  width: 5rem;
}

.form-background {
  background-image: var(--primary-background);
  background-repeat: repeat;
  background-position: center;
  background-size: contain;
  display: flex;
}
.form-background > div {
  flex: 1 1;
  align-self: center;
}
.form-container {
  width: 100vw;
  height: 100vh;
}

@media (max-width: 768px) {
  .form-background > div:last-child {
    display: none;
  }
}

