.form-background {
  background-image: var(--primary-background);
  background-repeat: repeat;
  background-position: center;
  background-size: contain;
  display: flex;
}
.form-background > div {
  flex: 1;
  align-self: center;
}
.form-container {
  width: 100vw;
  height: 100vh;
}

@media (max-width: 768px) {
  .form-background > div:last-child {
    display: none;
  }
}
