.responseContainer {
  min-width: 80%;
  min-height: 22%;
  display: flex;
  justify-content: center;
  background-color: var(--white-text);
  border-radius: 15px;
  position: absolute;
  top: 24%;
  padding: 1.5rem 0;
  animation: transform-box 0.5s ease-out forwards;
  z-index: 10;
}

.responseContainer hr {
  border: 1px solid var(--dark-text);
  opacity: 0.2;
  margin: 10px 0;
}
.responseContainer hr:last-child {
  display: none;
}

.responseContainer .service-box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: auto;
  flex: 1;
}

.responseContainer .service-box:nth-child(odd) {
  padding: 0 1rem;
}

.responseContainer .service-box .service-title {
  color: var(--dark-text);
  text-align: left;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  font-weight: 500;
  flex: 1;
}

.responseContainer .service-box .service-value {
  color: var(--very-dark-text);
  text-align: left;
  text-transform: capitalize;
  letter-spacing: 0.07em;
  font-weight: 500;
  font-size: 22px;
  flex: 2.5;
}

@keyframes transform-box {
  0% {
    transform: translateY(20%);
  }
  100% {
    transform: translateY(0%);
  }
}

@media (max-width: 768px) {
  .responseContainer {
    max-width: 60%;
    min-height: 30%;
    flex-direction: column;
    top: 20%;
  }
  .responseContainer hr {
    opacity: 0;
  }
}

@media only screen and (device-width: 812px) and (device-height: 375px) and (-webkit-device-pixel-ratio: 3) {
  .responseContainer {
    min-width: 90%;
    min-height: 35%;
    flex-direction: row;
    top: 42%;
  }
  .responseContainer hr {
    opacity: 0;
  }
  .responseContainer .service-box {
    align-items: center;
    margin-right: 0 !important;
    color: rebeccapurple;
  }
  .responseContainer .service-box .service-value {
    text-align: center;
    font-size: 15px;
  }
}

@media screen and (max-width: 750px) and (max-height: 800px) {
  .responseContainer {
    min-width: 90%;
    min-height: 35%;
    flex-direction: row;
    top: 38%;
  }
  .responseContainer hr {
    opacity: 0;
  }
  .responseContainer .service-box {
    align-items: center;
    margin-right: 0 !important;
  }
  .responseContainer .service-box .service-value {
    text-align: center;
    font-size: 15px;
  }
}

@media (max-width: 500px) {
  .responseContainer {
    min-width: 90%;
    min-height: 35%;
    flex-direction: column;
    top: 22%;
  }
  .responseContainer hr {
    opacity: 0;
  }
  .responseContainer .service-box {
    align-items: center;
    margin-right: 0 !important;
  }
  .responseContainer .service-box .service-value {
    text-align: center;
    font-size: 18px;
  }
}

@media (max-width: 374px) {
  .responseContainer {
    min-width: 90%;
    min-height: 35%;
    flex-direction: column;
    top: 25%;
  }
  .responseContainer hr {
    opacity: 0;
  }
  .responseContainer .service-box {
    align-items: center;
    margin-right: 0 !important;
  }
  .responseContainer .service-box .service-value {
    text-align: center;
    font-size: 18px;
  }
}
