.header-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 1.5rem;
}

.logout-button {
  position: absolute;
  top: 1rem;
  left: 1rem;
  padding: 0.5rem 0.8rem;
  font-size: 1rem;
  background-color: var(--very-dark-text);
  color: var(--white-text);
  border-radius: 10px;
  border: none;
  text-transform: capitalize;
  letter-spacing: 0.07em;
  font-weight: 500;
  cursor: pointer;
  transition: var(--transition);
}
.logout-button:hover {
  background-color: var(--dark-text);
}

.header-background {
  width: 100vw;
  height: 35vh;
  background-image: var(--primary-background);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.header-container .input-button-container {
  margin-top: 1.5rem;
  width: 36%;
}

.header-container .input-button-container input {
  font-size: 18px;
  font-family: var(--font-primary);
  color: var(--very-dark-text);
  padding: 0.9rem 1.2rem;
  border: none;
  width: 100%;
  border-radius: 15px 0 0 15px;
}

.header-container .input-button-container input:focus {
  outline: none;
}

.header-container .input-button-container .icon-button {
  min-width: 50px;
  min-height: 50px;
  background-color: var(--black-text);
  border-radius: 0 15px 15px 0;
  display: grid;
  place-items: center;
  cursor: pointer;
  border: none;
  transition: var(--transition);
}

.header-container .input-button-container .icon-button:hover {
  background-color: var(--very-dark-text);
}

.input-hidden {
  visibility: hidden;
}

.error-msg {
  color: var(--error-text);
  font-size: 14px;
  letter-spacing: 0.05rem;
  margin-top: 0.3rem;
}

@media only screen and (device-width: 812px) and (device-height: 375px) and (-webkit-device-pixel-ratio: 3) {
  .header-background {
    height: 58vh;
  }
  .header-container .input-button-container {
    width: 90%;
  }
}

@media screen and (max-width: 750px) and (max-height: 800px) {
  .header-background {
    height: 55vh;
  }
  .header-container .input-button-container {
    width: 90%;
  }
}

@media (max-width: 500px) {
  .header-background {
    height: 38vh;
  }
  .header-container .input-button-container {
    width: 90%;
  }
}

@media (max-width: 374px) {
  .header-background {
    height: 45vh;
  }
  .header-container .input-button-container {
    width: 90%;
  }
  .logout-button {
    padding: 0.4rem 0.5rem;
  }
}
