@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');

:root {
  --very-dark-text: #2b2b2b;
  --dark-text: #969696;
  --white-text: #fff;
  --black-text: #000;
  --error-text: #e67a7a;
  --font-primary: 'Rubik', sans-serif;
  --primary-background: url('../../assets/media/pattern-bg.png');
  --transition: all 0.2s ease;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 18px;
  font-family: 'Rubik', sans-serif;
}

.py-1 {
  padding: 1rem 0;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.header-text {
  font-size: 28px;
  font-weight: 500;
  color: var(--white-text);
  text-transform: capitalize;
  position: static;
}

.overflow-hide {
  overflow: hidden;
}

/* Loader */
.loader,
.loader:after {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.2em solid rgba(255, 255, 255, 0.2);
  border-right: 0.2em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.2em solid rgba(255, 255, 255, 0.2);
  border-left: 0.2em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
